import {
  RiReactjsFill,
  RiNodejsFill,
  RiJavascriptFill,
  RiBootstrapFill,
  RiTailwindCssFill,
} from "react-icons/ri";
import { TbBrandCSharp } from "react-icons/tb";
import { DiMongodb } from "react-icons/di";
import { SiExpress } from "react-icons/si";

const TechIcons = () => {
  return (
    <>
      <div className="flex flex-wrap m-auto py-8 gap-4">
        <DiMongodb
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
        <SiExpress
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
        <RiReactjsFill
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
        <RiJavascriptFill
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
        <RiNodejsFill
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />

        <RiTailwindCssFill
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
        <RiBootstrapFill
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />

        <TbBrandCSharp
          size={50}
          className="hover:fill-green-400 hover:scale-125 ease-in duration-300"
        />
      </div>
    </>
  );
};

export default TechIcons;
