import { useState } from "react";
import Swal from "sweetalert2";

export default function DataForm({ onUserAdded }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Server URL
  const serverUrl =
    "https://reactapp-janpluharweb-deploytest-backend.onrender.com/"; // || "http://localhost:4000";

  //----------------------------------------------

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[^\s@*]+@[^\s@*]+\.[^\s@*]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Collect data from the form
  const collectData = async (e) => {
    e.preventDefault();
    console.log("Sending data to:", serverUrl); // For debugging

    //----------------------------------------------

    // Email validation
    if (!validateEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email is not valid!",
      });
      return;
    }

    //hashing password
    const hash = require("object-hash");
    const hashedPassword = hash(password, {
      algorithm: "md5",
      encoding: "base64",
    });

    //----------------------------------------------

    // Send data to the server
    let result = await fetch(serverUrl, {
      method: "post",
      body: JSON.stringify({ name, email, password: hashedPassword }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data.error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "User created!",
          });
          // Reset the form
          setName("");
          setEmail("");
          setPassword("");

          // Call the parent function
          if (onUserAdded) {
            onUserAdded();
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });

    return result;
  };

  // Return the form
  return (
    <div className="mt-4 px-6 py-12 pb-16 bg-slate-900 rounded-2xl shadow-md">
      <form className="gap-4 flex flex-col items-center" onSubmit={collectData}>
        <h2 className="font-bold text-2xl text-maincolor">Signup form</h2>
        <div className="w-max">
          <label className="flex font-thin mb-2">Username</label>
          <input
            type="text"
            className="rounded-md px-4 py-1.5 border-solid border-2 border-maincolor"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="flex font-thin mb-2">Email</label>
          <input
            type="email"
            className="rounded-md px-4 py-1.5 border-solid border-2 border-maincolor"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="flex font-thin mb-2">Password</label>
          <input
            type="password"
            className="rounded-md px-4 py-1.5 border-solid border-2 border-maincolor"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="buttonMain">
          Submit
        </button>
      </form>
    </div>
  );
}
