import React from "react";
import { motion } from "framer-motion";
import TechIcons from "../components/others/TechIcons";

const Technologies = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="pl-6 flex flex-col gap-2">
        <h1 className="font-bold text-2xl">Technologies</h1>
        <p>
          There's will be detailed list of technologies I'm using to represent
          you my skills.
        </p>
      </div>
      <div className="bg-subpozadi min-h-40 mt-8 flex flex-col w-full m-auto px-8 py-6 rounded-3xl shadow-md">
        <TechIcons />
      </div>
    </motion.div>
  );
};

export default Technologies;
