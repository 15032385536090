import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Appsportfolio = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="px-4 pb-8 font-thin">
        <p>
          There's a list of applications what I've built to show you my skills.
          You can check them out.
        </p>
      </div>

      <section className="mb-8 rounded-xl grid grid-cols-1 bg-subpozadi shadow-2xl md:pl-14 md:grid-cols-2">
        <div className="grid grid-cols-1 gap-1 px-0 md:grid-cols-1">
          <div className="col-span-2 p-6 flex flex-col justify-center md:pr-6">
            <p className="text-maincolor text-2xl font-bold pb-4">Cinaty</p>
            <p>
              Application created for my daughter. The application represents
              the sale of a ticket to the cinema. Seat selection and booking,
              including price setting and subsequent sales calculation.
            </p>

            <div className="grid grid-cols-1 gap-2 pt-4 md:grid-cols-2">
              <div className="col-span-1">
                <Link
                  to="https://github.com/HonzaPluhar/CinatyTWCSS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="buttonSecondary w-full md:w-36">
                    Source Code
                  </button>
                </Link>
              </div>
              <div className=" md:col-end-4">
                <Link
                  to="https://cinaty.janpluhar.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="buttonMain w-full md:w-36">
                    Live demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pozadidark border-solid border-2 border-subpozadi rounded-xl">
          <img
            src="images/Cinaty-2.png"
            alt="Logo"
            className="max-h-96 rounded-r-lg shadow-lg mx-auto transition duration-300 ease-in-out transform"
          />
        </div>
      </section>

      <section className="mb-8 rounded-xl grid grid-cols-1 bg-subpozadi shadow-2xl md:pl-14 md:grid-cols-2">
        <div className="grid grid-cols-1 gap-1 px-0 md:grid-cols-1">
          <div className="col-span-2 p-6 flex flex-col justify-center md:pr-6">
            <p className="text-maincolor text-2xl font-bold pb-4">
              Simple CRUD
            </p>
            <p>
              This is a simple app to represent CRUD operations with mongodb and
              server side api connection with hashed password.
            </p>

            <div className="grid grid-cols-1 gap-2 pt-4 md:grid-cols-2">
              <div className="col-span-1">
                <Link to="#">
                  <button className="buttonDisabled w-full md:w-36">
                    Source Code
                  </button>
                </Link>
              </div>
              <div className=" md:col-end-4">
                <Link to="/signupapp">
                  <button className="buttonMain w-full md:w-36">
                    Live demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pozadidark border-solid border-2 border-subpozadi rounded-xl">
          <img
            src="images/simpleCrud.png"
            alt="Logo"
            className="max-h-96 rounded-r-lg shadow-lg mx-auto transition duration-300 ease-in-out transform"
          />
        </div>
      </section>
    </motion.div>
  );
};

export default Appsportfolio;
